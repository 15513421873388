import React from 'react';
import '../../styles/gst.css';
import Testimonial from '../../components/Testimonial';
import { Seo } from '../../components/seo';
import FAQAccord from '../../components/GFlush';
import Layout from '../../components/Layout';
import Counter from '../../components/Counter';
import Guidance from '../../components/GSTTemp/Guidance';
// import Hero from "../../components/Hero";
import AboutWithForm from '../../components/GSTTemp/AboutWithForm';
import ScrollNav from '../../components/GSTTemp/ScrollNav';
import ImgContent from '../../components/GSTTemp/ImgContent';
import ContentForm from '../../components/GSTTemp/ContentForm';
import Cta from '../../components/Cta';
import SliderContent from '../../components/GSTTemp/SliderContent';
import ContentImg from '../../components/GSTTemp/ContentImg';
import Package from '../../components/GSTTemp/Package';

export default function FcraRegistration() {
  /* Slider */
  // const fcrSlider = [
  //   {
  //     header_color: false,
  //     content_color: false,
  //     marginTop: 30,
  //     background: `linear-gradient(139.4deg, rgb(207, 249, 216) 28.54%, rgb(243, 238, 160) 104.43%)`,
  //     header: `Online FCRA Registration`,
  //     content: `Secure FCRA registration for your NGO`,
  //     image: "/imgs/business/sliders/pvt.png",
  //     alt_tag: "Online FCRA Registration Near Banglore",
  //   },
  // ];

  /* Package Data */
  const PackageCompData = {
    header: `FCRA Registration`,
    buyBtnLink: `#pricing-buy`,
    price: `14999`,
    content: [
      {
        points: `PAN & TAN Registration `,
        icon: "/imgs/icons/gst.png",
        alt_tag: "PAN & TAN Registration",
        background: "",
        plus: "+",
      },
      {
        points: `PF + ESIC + Professional Tax`,
        icon: "/imgs/icons/itr_filing.png",
        alt_tag: "TODAYFILINGS ITR E-filing service",
        background: "bg-f78acb",
        plus: "+",
        plus_dnone: "d-none d-md-block",
      },
      {
        points: `MSME Registration`,
        icon: "/imgs/icons/enterprises.png",
        alt_tag: "MSME Registration",
        background: "bg-eb6b3d",
        plus: "+",
      },
      {
        points: `Tax Consultancy Service `,
        icon: "/imgs/icons/consultant.png",
        alt_tag: "Tax Consultancy Service",
        background: "bg-c679e3",
      },
    ],
  };
  /* About Data With Form*/
  const fcrAboutData = {
    header: `Online FCRA Registration`,
    sub_title: `Most Success Foreign Contribution Regulation Act service providers in Hosur. `,
    content_paragraph: [
      `FCRA is an Indian law that regulates the acceptance of aid or donations from outside. Charitable Trusts, 
      Societies and Section 8 Companies that receive donations or contributions from abroad must register under the
       FCRA. The application must be registered under the Societies Registration Act of 1860.`,

      // `The Foreign Contribution Regulation Act, or FCRA for short, is a law that controls and oversees foreign
      // contributions made to or received in India.`,
    ],
  };

  /* Scroll Nav Data */
  const fcrScrollId = [
    {
      id: `#fcrProcedure`,
      heading: `Procedure`,
    },
    {
      id: `#fcrDocument`,
      heading: `Documents`,
    },
    {
      id: `#fcrRenewal`,
      heading: `Renewal`,
    },
    {
      id: `#Non`,
      heading: `Non-eligible`,
    },

    {
      id: `#fcrCancel`,
      heading: `Cancellation`,
    },
  ];

  /* Img Content Component Data */
  const fcrIcData = {
    id: 'fcrProcedure',
    mt_div: '',
    mt_img: '',
    header: 'Procedure For FCRA Registration',
    image: '/imgs/registration/ngo/fcra-registration.png',
    alt_tag: 'non-government organization',
    points: [
      {
        head: ``,
        content: `Apply online by filling out the required information on the FCRA's official website,
         https://fcraonline.nic.in, in the specified online form FC-3.`,
        icon: true,
      },
      {
        head: '',
        content: `Complete the form completely, then scan and attach the required documents in the specified
         format, typically PDF.`,
        icon: true,
      },
      {
        head: '',
        content: `After completing an online payment through an internet money transfer method, 
        the online FCRA registration will be finished.`,
        icon: true,
      },
    ],
  };

  /* Content Form Component Data */
  const fcrCfData = {
    id: 'fcrDocument',
    mt_div: 'mt-5',
    mt_img: 'mt-5',
    header: 'Documents Required For FCRA Registration',
    body: [
      {
        head: ``,
        points: `Self-attested copies of the entity's COI, trust deed, or any other registered license or 
        certificate that belongs to that entity, as well as the PAN number of an NGO.`,
        icon: true,
      },
      {
        head: ``,
        points: `A copy of the entity's and associations' MOA and AOA the Chief Justice's signature in the 
        format required a thorough account or report on the three years' worth of activities.`,
        icon: true,
      },
      {
        head: ``,
        points: `the decision made by a body with authority granted by an NGO certificate obtained in 
        accordance with sections 80G and 12A of the Internal Revenue Code.`,
        icon: true,
      },
      {
        head: ``,
        points: `The Income Tax Act's Sections 80G and 12A exclude all registered NGOs from paying taxes.`,
        icon: true,
      },
      {
        head: ``,
        points: `A copy of the financial statements, which were reviewed by a qualified and authorized CA, 
        includes the P&L account, information on income and expenses, and a cash flow statement for the three years prior.`,
        icon: true,
      },
    ],
  };

  /*  Content Img Component Data */
  const fcrCiData = {
    id: 'Non',
    background: 'main-content',
    mt_div: '',
    header:
      'The Following Are The Entities That Are Not Eligible To Apply For FCRA Registration',
    paragraph: ``,
    points: [
      {
        head: '',
        content: `The candidate is a fake or a Benami."`,
        icon: true,
      },
      {
        content: `A candidate who has been falsely accused of or prosecuted for any civil disputes 
        brought up in any location within the designated district or elsewhere in the nation.`,
        icon: true,
      },
      {
        content: `Applicant being prosecuted for engaging in or committing any unfair or unlawful behavior.`,
        icon: true,
      },
      {
        content: `Participant in any violent behavior has used any contributions or funds 
        from abroad for their own usel`,
        icon: true,
      },
      {
        content: `Any business that has been penalized for accepting a foreign grant or 
        foreign receipt in accordance with a legal requirement.`,
        icon: true,
      },
      {
        content: `Any such foreign grant adoption that has a negative impact such as 
        Community Interest,The independence of India and connection to any state.`,
        icon: true,
      },
    ],
    image: '/imgs/registration/msme/msme-reg.png',
    alt_tag: 'Not Eligible  For FCRA Registration',
  };

  /* ImgSlider Data */
  const fcrImages = [
    '/imgs/registration/ipr/td8.png',
    '/imgs/registration/ipr/td4.png',
    '/imgs/registration/ipr/td2.png',
  ];
  /*  Slider Content Component Data */
  const fcrSCData = {
    id: 'fcrRenewal',
    background: [],
    mt_div: 'mt-3',
    header: 'License Renewal For The FCRA',
    content: [
      {
        head: '',
        points: `The FCRA registration is valid for approximately five years, 
        following which the owner may apply to have the license renewed.An company or 
        body must submit a renewal application within the deadline.`,
        icon: true,
      },
      {
        points: `Six months at the very least before the FCRA license is set to expire. In situations when the organization is engaged on a multi-year
         transaction or project, at least 12 months must have passed since the FCRA online registration's expiration date.`,
        icon: true,
      },
    ],
    images: fcrImages,
  };
  /* Img Content Component Data */
  const fcrAIcData = {
    id: 'fcrCancel',
    mt_div: 'mt-5',
    mt_img: '',
    header: 'FCRA Registration Cancellation Or Suspension',
    image: '/imgs/registration/msme/msme-slider.png',
    alt_tag: 'non-government organization',
    Paragraph: `Concerned authorities may give notice to the entity in the event that they believe the 
    registered organization is not operating in accordance with the law and that their permit will be revoked.
     The following are only a handful of the grounds for license cancellation:`,
    points: [
      {
        head: 'Eligibility for Trademark Registration',
        content: ``,
        icon: false,
      },
      {
        content: `If the NGO disregards the Foreign Contribution (Regulation) Act's stipulated rules.`,
        icon: true,
      },
      {
        content: `If the company neglects to prepare the annual report by the deadline.`,
        icon: true,
      },
      {
        content: `If any inquiries are conducted into accusations of illicit activity made against this 
        company and if the allegations are found to be true.`,
        icon: true,
      },
      {
        content: `Foreign contributions are not used for the organization's primary objectives or for achieving 
        the objectives specified during the FCRA registration procedure.`,
        icon: true,
      },
    ],
  };

  var FAQ_data = [
    {
      header: `What are the various FCRA registration processes?`,
      body: [
        {
          content: `Two categories of FCRA registration `,
          icon: false,
        },
        {
          content: `FCRA registration with the proper authorization.`,
          icon: true,
        },
        {
          content: `FCRA registration done correctly.`,
          icon: true,
        },
      ],
    },

    {
      header:
        'What kind of compliance is required following FCRA Registration?',
      body: [
        {
          content: `Income Statement Copies.`,
          icon: true,
        },
        {
          content: `The balance sheet's duplicate.`,
          icon: true,
        },
        {
          content: `Foreign contribution receipt report or statement.`,
          icon: true,
        },
        {
          content: `Payments and expenses that an entity has incurred`,
          icon: true,
        },
      ],
    },
    {
      header: `What is the final day for the FCRA-registered organization to submit
      its annual report?`,
      body: [
        {
          content: ` The FC-4 annual compliance form must be submitted within nine months
          after the fiscal year's conclusion, or before December 31 of each
          year.`,
          icon: false,
        },
      ],
    },
    {
      header: `How long is an FCRA registration valid?`,
      body: [
        {
          content: `Once granted, an FCRA Registration is good for five years and can be
          renewed by applying six months before to the expiration date.`,
          icon: false,
        },
      ],
    },
    {
      header: `What are the rules for FCRA registration?`,
      body: [
        {
          content: `The not-for-profit entity must have also been in existence for a minimum of three years 
          while making the FCRA application and should not have received any foreign contribution prior to that 
          without the Government's approval.`,
          icon: false,
        },
      ],
    },
    {
      header: `How long does it take to approve FCRA registration?`,
      body: [
        {
          content: `The slogan of a NGO need to not be to misuse the fund but to work for the social upliftment of 
          the inadequate and the needy and give the world a boost. Generally, the time taken for fcra registration 
          is four months but it could differ if the NGO has several branches.`,
          icon: false,
        },
      ],
    },
    {
      header: `How many types of registration can be done under FCRA?`,
      body: [
        {
          content: `The two types of FCRA registration are as follows: Prior Permission Certificate: This sort of 
          certificate is possible after one year. Permanent Certificate: It is available for a span of 5 years.`,
          icon: false,
        },
      ],
    },
    {
      header: `Who approves FCRA registration?`,
      body: [
        {
          content: `
          An organization having a definite cultural, economic, educational, religious or social program shall accept 
          foreign contribution, only after such organization obtains a certificate of registration from the Central 
          Government.`,
          icon: false,
        },
      ],
    },
    {
      header: `Is 12A registration compulsory for FCRA?`,
      body: [
        {
          content: `FCRA Registration and Registering 12A or 80G – An Overview It is deemed optional for 
          trust/NGO/ Section 8 company.`,
          icon: false,
        },
      ],
    },
    {
      header: `Can we withdraw cash from FCRA account?`,
      body: [
        {
          content: ` Mostly, Bank is not providing any ATM or Debit Cards for FC Bank Account. However, 
          if you have such card for FC Bank Account, do not use for cash withdrawals for online payments.`,
          icon: false,
        },
      ],
    },
  ];
  return (
    <div>
      <Seo
        title='FCRA Registration'
        keywords='Keywords'
        description='Description'
        name='TODAYFILINGS'
        type='Online E-filing'
        author='TODAYFILINGS'
      />
      <Layout>
        <main id='main'>
          {/* <Hero sliders={fcrSlider} /> */}

          <Package packageData={PackageCompData} />

          <AboutWithForm about_content={fcrAboutData} />

          <Counter />

          <ScrollNav scroll_data={fcrScrollId} />

          <ImgContent item={fcrIcData} />

          <ContentForm CFSection_data={fcrCfData} />

          <SliderContent ScSection_data={fcrSCData} />

          <ContentImg CISection_data={fcrCiData} />

          <ImgContent item={fcrAIcData} />

          <Cta />
          <Guidance />

          <Testimonial />

          <FAQAccord items={FAQ_data} />
        </main>
      </Layout>
    </div>
  );
}
